body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiTabs-flexContainer-40 {
  display: flex;
  justify-content: center;
}

#root {
  display: flex;
  flex: 0 1 50%;
  z-index: 1;
  color: #222;
}

.info {
  display: block;
  width: 250px;
  margin: auto;
}

.contacts {
  display: block;
  margin: auto;
  width: 250px;
  font-family: Roboto;
  font-size: 1rem;
}

@media only screen and (max-width: 40em) {
  #root{ flex: 0 0 80%; }
}

.App {
  background-color: rgba(255, 255, 255, .5);
  font-family: 'Roboto';
  font-size: 2rem;
  font-weight: 700;
  width: 100%;
}

.particles-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #222;
  z-index: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

#particles-js {
  width: 100%;
  height: 100%;
}
